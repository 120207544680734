import React from "react";
import "./Footer.css";
import Github from "../../assets/github.png";
import Instagram from "../../assets/instagram.png";
import Linkdin from "../../assets/linkedin.png";
import logo from "../../assets/logo.png";

const Footer = () => {
  return (
    <div className="Footer-container">
      <hr />
        <div className="footer">
            <div className="social-link">
            <img src={Github} alt="" />
            <img src={Linkdin} alt="" />
            <img src={Instagram} alt="" />
            </div>

            <div className="logo-f">
            <img src={logo} alt="" />
            </div>
      </div>

      <div className="blur footer-blur-1"></div>
      <div className="blur footer-blur-2"></div>

    </div>
  );
};

export default Footer;
